'use client'

import {
	type Dispatch,
	type ReactNode,
	createContext,
	useEffect,
	useState,
} from 'react'

import { baseSession } from '../constants'
import type { Session } from '../types/session'
import { getSession } from '../utils/getSession'

export const SessionContext = createContext<Session | null>(null)
export const SetSessionContext = createContext<Dispatch<Session> | null>(null)

type SessionProviderProps = {
	readonly children: React.ReactNode
}
export function SessionProvider({ children }: SessionProviderProps): ReactNode {
	const [session, setSession] = useState(baseSession)

	const handleSession = async (): Promise<void> => {
		const sessionData = await getSession()
		if (sessionData) {
			setSession({ ...sessionData, isReady: true })
		}
	}

	useEffect(() => {
		if (!session.isReady) {
			handleSession()
		}
	}, [])

	return (
		<SessionContext.Provider value={session}>
			<SetSessionContext.Provider value={setSession}>
				{children}
			</SetSessionContext.Provider>
		</SessionContext.Provider>
	)
}
